.ReactModal__Content.ReactModal__Content--after-open[aria-label="Franchise Details"] {
  @media(max-width: 1200px) {

  }

  @media(max-width: 500px) {
    width: 90vw !important;
  }

  @media(max-width: 460px) {
    width: 80vw !important;
  }
}

.dashboard-popup-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,0.3);
  z-index: 9999;

  @media(max-width: 900px) {
    height: 120%;
    // width: 120%;
  }
}

.dashboard-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  min-height: 50%;
  background: #fff;
  padding: 3rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;

  @media(max-width: 1050px) {
    width: 90%;
  }

  @media(max-width: 600px) {
    padding: 1rem;
  }
}

.dashboard-popup-top {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 4rem;

  @media(max-width: 640px) {
    flex-direction: column;
  }
}

.dashboard-popup-top-business,
.dashboard-popup-top-data {
  flex: 1;
}

.dashboard-popup-top-business {
  color: #662e89;
  font-weight: 700;
  font-size: 2rem;
}

.dashboard-popup-top-data {
  display: flex;
  gap: 2rem;
  color: rgba(0,0,0,0.2);
  font-weight: 700;
  font-size: 1.6rem;
}

.dashboard-popup-top::after {
  content: '';
  position: absolute;
  display: block;
  width: calc(100% + 6rem);
  margin-left: -3rem;
  height: 1px;
  bottom: -3rem;
  background: rgba(0,0,0,0.2);
}

.dashboard-popup-middle {
  flex: 1;
  display: flex;
  gap: 3rem;

  @media(max-width: 1200px) {
    display: block;
  }
}

.dashboard-popup-stats {
  display: flex;
  flex-direction: column;
}

.dashboard-popup-stat {
  display: flex;
  gap: 1rem;
}

.dashboard-popup-stat-label,
.dashboard-popup-stat-value {
  background: #F6F6F9;
  font-size: 1.4rem;
  font-weight: 700;
  padding: 0.8rem;
  border-radius: 1rem;
}

.dashboard-popup-stat-label {
  flex: 2;
}

.dashboard-popup-stat-value {
  flex: 1;
}

.dashboard-popup-middle-left,
.dashboard-popup-middle-right {
  flex: 1;
}

.dashboard-popup-middle-left {
  margin-right: 3rem;

  @media(max-width: 1200px) {
    margin-bottom: 4rem;
  }
}

.dashboard-popup-stats {
  gap: 1.5rem;
}

.dashboard-popup-stat-value {
  text-align: center;
  color: #662e89;
}

.dashboard-popup-stat-value-negative {
  color: #E51A33;
  text-decoration: underline;
}

.dashboard-popup-star {
  width: 2rem;
  height: 2rem;
}

.reviews-top-text {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  font-weight: 700;
}

.reviews-top-text h3 {
  font-size: 1.5rem;
}

.reviews-top-text p {
  font-size: 1.4rem;
  color: rgba(0,0,0,0.2);
}

.dashboard-popup-stars {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.dashboard-popup-reviews-slider {
  margin-left: -3rem;
}

.dashboard-popup-review-slide {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  margin-bottom: 3rem;
}

.dashboard-popup-review-slide-top {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 3rem;
  border-radius: 0.5rem;
}

.dashboard-popup-review-name {
  font-size: 1.4rem;
  font-weight: 700;
}

.dashboard-popup-review-date {
  font-size: 1.3rem;
  color: rgba(0,0,0,0.2)
}

.dashboard-popup-review-slide-top::after {
  content: '';
  position: absolute;
  display: block;
  width: calc(100% + 3rem);
  margin-left: -1.5rem;
  height: 1px;
  bottom: -1.5rem;
  background: rgba(0,0,0,0.2);
}

.dashboard-popup-review-slide-middle {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.dashboard-popup-reviews-slider .eAyARa,
.dashboard-popup-reviews-slider .eWjCzc {
  background: #fff;
  border: none;
  padding: 0;
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  line-height: 2rem;
  font-size: 1rem;
  box-shadow: none;
}

.dashboard-popup-reviews-slider .rec-pagination {
  display: none;
}

.dashboard-popup-reviews-slider .rec-slider-container {
  box-shadow: 0px 0px 1rem 1px rgba(0,0,0,0.2);
  margin-top: 3rem;
  border-radius: 0.5rem;
}

.dashboard-popup-review-number {
  text-align: center;
  margin-top: 3rem;
  color: rgba(0,0,0,0.4);
  font-size: 14px;
}

.dashboard-popup-bottom {
  display: flex;
  background: #F3F3F6;
  padding: 3rem;
  width: calc(100% + 6rem);
  margin-left: -3rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  position: relative;
  bottom: -3rem;

  @media(max-width: 800px) {
    flex-direction: column;
  }
}

.dashboard-popup-bottom-left {
  flex: 1;
}

.dashboard-popup-negative-desc {
  color: #333;
  font-weight: 500;
  font-size: 16px;
}

.dashboard-popup-negative-comment-number {
  color: rgba(0,0,0,0.4);
  margin-top: 0.5rem;
  font-size: 16px;
}

.dashboard-popup-bottom-right {
  width: 70%;
  margin-left: 10%;

  @media(max-width: 800px) {
    width: 100%;
    margin-left: 0;
  }
}

.dashboard-popup-review-number {
  display: none;
}

.rec-item-wrapper .dashboard-popup-review-number {
  display: block;
}

.rec-carousel {
  @media(max-width: 1200px) {
    margin-left: 3.5%;
  }

  @media(max-width: 800px) {

  }

  @media(max-width: 600px) {
    margin-left: 8%;
  }
}

.sc-bczRLJ.eAyARa,
.sc-bczRLJ.eWjCzc {
  border: none;
  padding: 0;
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  line-height: 2rem;
  font-size: 1rem;
  box-shadow: none;
  background: transparent;
}

.carousel-negative-comments .sc-dkzDqf.hHJTFD.rec-slider-container {
  border: 1.5px solid #e51a33;
  border-radius: .5rem;
}

.dashboard-popup-negative-comment-slide {
  display: flex;
  background: #fff;
  padding: 2rem;
  width: 100%;
}

.dashboard-popup-negative-comment-name-date {
  flex: 1;
}

.dashboard-popup-negative-comment-name {
  color: #e51a33;
  font-weight: 700;
  width: 115%;
}

.dashboard-popup-negative-comment-date {
  color: rgba(0,0,0,0.4);
}

.dashboard-popup-negative-comment-text {
  flex: 6;
  padding-left: 5rem;
  font-weight: 500;
}

.sc-iBkjds.kdCslO.rec-pagination {
  display: none;
}

.dashboard-popup-close {
  height: 2rem;
  width: 2rem;
  position: absolute;
  right: 2rem;
  top: 2rem;
  cursor: pointer;
}

.rec.rec-carousel-item.rec-carousel-item-visible {
  position: relative;
}

.sc-gsnTZi.guIbWC.rec.rec-item-wrapper {
  flex-direction: column;
}

.dashboard-popup-review-slide {
  margin-bottom: 6rem;
}

.dashboard-popup-review-number {
  position: absolute;
  bottom: 4.5rem;
  left: 50%;
  transform: translateX(-50%);
}

.rec-carousel-item .dashboard-popup-review-number {
  opacity: 0;
}

.rec-carousel-item-visible .dashboard-popup-review-number {
  opacity: 1;
}