.react-confirm-alert-button-group > button {
  background: #662e89 !important;
}

.react-confirm-alert-button-group > button:hover {
  background: #999 !important;
}

.react-confirm-alert-body {
  font-size: 1.5rem !important;
}

.react-confirm-alert-body h1 {
  margin-bottom: 1rem;
  font-size: 2rem !important;
}
