.mobile-navbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 1.5rem 1rem;
  z-index: 9999;
  display: none;
  background: #fff;

  @media(max-width: 900px) {
    display: flex;
    gap: 6rem;
    justify-content: center;
  }
}

.mobile-sidebar-item {
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.4rem;
  color: #ababb7;
  fill: #ababb7
}

.mobile-sidebar-item-active {
  color: #662e89;
  fill: #662e89;
}