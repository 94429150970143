.add-new-franchise-wrapper {
  flex: 1;
  padding-right: 1rem;

  @media(max-width: 900px) {
    margin-top: 8.5rem;
    padding-left: 1rem;
    margin-bottom: 8.5rem;
    padding-right: 0;
  }

  .top-right-info {
    @media(max-width: 900px) {
      display: none !important;
    }
  }
}

.add-new-franchise-container {
  background: #fff;
  padding: 3rem;
  padding-top: 1.5rem;
  border-radius: 10px;
  position: relative;
}

.add-new-franchise-title {
  font-size: 1.8rem;
  color: rgba(0,0,0,0.4);
  font-weight: 500;
  margin-bottom: 4rem;
}

.add-new-franchise-title::after {
  content: '';
  display: block;
  position: absolute;
  top: 5.5rem;
  left: 0;
  height: 1px;
  background: rgba(0,0,0,0.2);
  width: 100%;
}

.add-new-franchise-container .form-fields-wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(30rem, 30%));
  gap: 2rem;
  margin-bottom: 3rem;

  @media(max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.add-new-franchise-container {
  input[type='submit'] {
    @media(max-width: 640px) {
      width: 100% !important;
    }
  }
}

.add-new-franchise-container .terms-and-conditions {
  flex-direction: column;
  align-items: left;
  gap: 1rem;
  align-self: flex-end;

  span {
    color: #662E89;
    font-weight: 500;
  }
}

.add-new-franchise-container input[type='submit'] {
  background: #662E89;
  color: #fff;
  padding: 1.5rem 2.5rem;
  border-radius: 0.5rem;
  border: none;
  width: 62%;
  text-align: center;
  font-size: 1.5rem;
  font-family: inherit;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.add-new-franchise-container .business-id {
  width: 62%;

  @media(max-width: 640px) {
    width: 100%;
  }
}

.add-new-franchise-container .business-id-info {
  font-size: 1.5rem;
  color: #333;
  margin: 1rem 0;
}

.text-red-400 {
  color: rgb(248, 113, 113) !important;
  font-size: 1rem;
}

.add-franchise-input_error {
  border: solid 1px rgb(248, 113, 113) !important;
}