.dashboard-wrapper {
  overflow: scroll;
  background-color: #f6f6f9;
  height: 100vh;
  flex: 1;

  @media (max-width: 900px) {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 8.5rem;
    margin-bottom: 8.5rem;
  }

  .top-right-info {
    @media (max-width: 900px) {
      display: none !important;
    }
  }
}
.dashboard-wrapper {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.dashboard-wrapper::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.dashboard-heading {
  color: #662e89;
  font-size: 2.5rem;
  font-weight: 800;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
}

.spinner {
  width: 10rem;
  height: 10rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-10rem, -10rem);
  animation: spinner-animation 1s linear infinite;
}

@keyframes spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
