body {
  background: #F6F6F9;
}

.login-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 3rem;
  padding-top: 1rem;
  border-radius: 10px;
  width: 30%;

  @media(max-width: 1024px) {
    width: 50%;
  }

  @media(max-width: 768px) {
    width: 70%;
  }

  @media(max-width: 500px) {
    width: 95%;
  }
}

.login-logo {
  width: 10rem;
  height: 10rem;
  display: block;
  margin: 0 auto;
}

.login-form-group {
  display: flex;
  flex-direction: column;
}

.login-form-group label {
  font-size: 14px;
  color: #333;
  margin-bottom: 7px;
}

.login-form-group input {
  border: none;
  border: 1px solid #333;
  border-radius: 3px;
  font-size: 14px;
  font-family: inherit;
  padding: 0.5rem 1rem;
}

.login-form-group input:focus {
  outline: none;
}

.login-form-group:first-child {
  margin-bottom: 2rem;
}

.login-button {
  border: none;
  margin-top: 3rem;
  background: #662E89;
  color: #fff;
  padding: 1rem;
  font-size: 1.4rem;
  font-family: inherit;
  width: 100%;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
}

.login-forgot-password {
  color: #662E89;
  font-size: 1.4rem;
  display: block;
  text-align: end;
  margin-top: 2rem;
  text-decoration: none;
  font-weight: 500;
}

.forgot-password-button {
  margin-top: 0;
}