.dashboard-stats-card {
  background-color: #662E89;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5rem;
  border-radius: 0.7rem;
  margin-bottom: 2rem;

  @media(max-width: 1250px) {
    flex-direction: column;
    gap: 0;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.dashboard-stats-content {
  display: flex;
  justify-content: space-between;
  flex: 3;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  font-size: 1.4rem;
  gap: 2rem;

  @media(max-width: 1250px) {
    width: 100%;
    padding-right: 2rem;
    padding-top: 0;
    padding-bottom: 1rem;
  }

  @media(max-width: 475px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
  }
}

.dashboard-stats-content-label {
  display: flex;
  align-items: center;
  gap: 1rem;

  @media(max-width: 1000px) {
    gap: 0.5rem;
  }

  @media(max-width: 475px) {
    font-size: 1.3rem !important;
  }
}

.dashboard-stats-content-value {
  font-weight: 700;
  font-size: 3rem;

  @media(max-width: 1000px) {
    font-size: 1.8rem;
  }
}

.dashboard-stats-location-and-button {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: 2.5rem;

  @media(max-width: 1250px) {
    width: 100%;
    padding-left: 2.5rem;
  }
}

.dashboard-stats-add-franchise-button {
  background-color: #F0AF13;
  color: #662E89;
  font-weight: 800;
  text-transform: uppercase;
  padding: 1.5rem 2rem;
  border-radius: 0.7rem;
  border: none;
  cursor: pointer;

  @media(max-width: 1250px) {
    width: 100%;
  }
}