.settings {
    width: 50%;
}

.settings form .form-group {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin: 2rem 0;
}

.settings label {
  width: 100%;
  margin: 0.5rem;
  font-size: 1.5rem;
}

.settings input {
  width: 100%;
  padding: 0.7rem 1rem;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  font-size: 1.6rem;
  font-family: inherit;
}

.settings button {
  background: #662e89;
  color: #fff;
  padding: 2rem 2.5rem;
  border-radius: 0.5rem;
  border: none;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-family: inherit;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.settings button:hover {
  background-color: #f0af13 !important;
  color: #333 !important;
}

@media (max-width: 800px) {
  .settings input {
    margin: 10px 0;
  }

  .settings {
    flex-direction: column;
    align-items: stretch;
  }
}
