.mobile-top {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  padding: 1.5rem 1rem;
  z-index: 9999;

  @media(max-width: 900px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.mobile-logo {
  width: 5rem;
}