.sidebar-outer-wrapper {
  width: 20rem;
  transition: width 0.5s ease-in-out;

  @media(max-width: 900px) {
    display: none;
  }
}

.sidebar {
  width: 20rem;
  position: relative;
  height: 100%;
  min-height: 100vh;
  background-color: #fff;
  transition: width 0.5s ease-in-out;
  z-index: 9999;

  @media(max-width: 900px) {
    display: none;
  }
}

.sidebar-container {
  overflow: hidden;
}

.sidebar-logo {
  width: 75px;
  height: 75px;
  margin: 2rem auto;
  display: block;
}

.sidebar-item {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: #ababb7;
  font-weight: bold;
  justify-content: center;
  margin-bottom: 2rem;
  margin-left: 3rem;
  text-decoration: none;
  transition: color 0.5s ease-in-out;
}

.sidebar-item:hover {
  color: #662E89;
}

.sidebar-item:hover .sidebar-item-icon {
  fill: #662E89;
}

.sidebar-item-active {
  color: #662E89;
}

.sidebar-item-active .sidebar-item-icon {
  fill: #662E89;
}

.sidebar-item-icon {
  flex: 1;
  fill: #ababb7;
  transition: fill 0.5s ease-in-out;
}

.sidebar-item-text {
  flex: 3;
}

.sidebar-collapse-icon {
  position: absolute;
  top: 50%;
  right: -1.5rem;
  cursor: pointer;
  transform: translateY(-50%);
}

.sidebar-closed {
  width: 0;
}