@import "./assets/styles/sidebar";

@import "./assets/styles/dashboard/dashboard";
@import "./assets/styles/dashboard/dashboard-stats";
@import "./assets/styles/dashboard/dashboard-franchises";
@import "./assets/styles/dashboard/dashboard-popup";
@import "./assets/styles/billing/billing";
@import "./assets/styles/settings/settings";
@import "./assets/styles/login/login";
@import "./assets/styles/add-new-franchise/add-new-franchise";
@import "./assets/styles/components/form-field.scss";
@import "./assets/styles/components/confirmation-modal.scss";
@import "./assets/styles/mobile-navbar.scss";
@import "./assets/styles/mobile-top.scss";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  line-height: 1.6;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.main-wrapper {
  padding-right: 6rem;

  @media (max-width: 900px) {
    padding-right: 0rem;
  }
}

.route-wrapper {
  display: flex;
  gap: 6rem;
}

.add-button:hover {
  background-color: #f0af13 !important;
  color: #333 !important;
}
