.dashboard-franchises-wrapper {
  background: #fff;
  padding: 2rem;
  border-radius: 0.5rem;
}

.dashboard-franchises-heading {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.dashboard-franchises-table-wrapper {
}

.dashboard-franchises-table {
  table-layout: auto;
  width: 100%;
}

.dashboard-franchises-thead {
  background-color: #c495fb;
  color: #fff;
  text-align: center;
}

.dashboard-franchises-table-wrapper {
  width: 100%;

  @media (max-width: 1250px) {
    width: 820px;
  }

  @media (max-width: 1200px) {
    width: 700px;
  }

  @media (max-width: 1080px) {
    width: 600px;
  }

  @media (max-width: 950px) {
    width: 500px;
  }

  @media (max-width: 900px) {
    width: 670px;
  }

  @media (max-width: 768px) {
    width: 670px;
  }

  @media (max-width: 600px) {
    width: 550px;
  }

  @media (max-width: 460px) {
    width: 410px;
  }
}

.dashboard-franchises-th {
  padding: 1rem;
  font-size: 1.2rem;
  // width: 14%;
  @media (max-width: 1250px) {
    min-width: 15rem;
  }
}

.dashboard-franchises-tbody {
  background: #fff;
}

.dashboard-franchises-tbody-tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.dashboard-franchises-td {
  text-align: center;
  padding: 1rem;
  font-weight: 600;
  font-size: 1.2rem;
}
