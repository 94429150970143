.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.form-group input {
  padding: 0.7rem 1rem;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  font-size: 1.6rem;
  font-family: inherit;
}

.form-group input:focus {
  outline: none;
}

.br-dropdown {
  margin-top: 0.1rem;
}

.Dropdown-control {
  border-radius: 5px !important;
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
  font-size: 1.4rem !important;
}

.Dropdown-arrow {
  top: 17px !important;
}

.Dropdown-menu {
  font-size: 1.4rem !important;
}
