.billing-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3rem;
  border-radius: 1rem;
  margin-bottom: 6rem;
}

.billing-wrapper {
  overflow: scroll;
  height: 100vh;
  background-color: #f6f6f9;
  flex: 1;

  @media (max-width: 900px) {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 8.5rem;
    margin-bottom: 8.5rem;
  }

  .top-right-info {
    @media (max-width: 900px) {
      display: none !important;
    }
  }
}

.billing-wrapper {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.billing-wrapper::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.billing-subscription-info-heading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.license-payment-price {
  display: flex;
  justify-content: space-between;
}

.license-payment {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.billing-subscription-info-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: start;
  }
}

.billing-subscription-info-left,
.billing-subscription-info-right {
  flex: 1;

  @media (max-width: 1100px) {
    width: 100%;
  }
}

.billing-subscription-info-right .add-location {
  display: flex;
  align-items: center;
}

.billing-subscription-info-left {
  position: relative;
  background-color: #f7f6fb;
  padding: 30px;
  border-radius: 5px;
}

.license-title {
  font-size: 18px;
  font-weight: bold;
}

.license-info {
  color: #662e89;
  font-size: 16px;
  font-weight: bold;
}

.license-payment {
  gap: 30px;
}

.payment-days {
  color: #999;
  font-size: 14px;
}

.payment-due {
  font-size: 16px;
  font-weight: bold;
}

.payment-date {
  color: #662e89;
}

.price-container .price {
  font-size: 25px;
  font-weight: bold;
  color: #662e89;
}

.checkmark-icon {
  background-color: #662e89;
  color: #fff;
  padding: 10px 8px;
  border-radius: 50%;
}

.checkmark {
  position: absolute;
  top: -15px;
  right: -15px;
}

.add-location-desc {
  color: #662e89;
  font-size: 20px;
  font-weight: bold;
}

.add-location-price {
  font-weight: bold;
  font-size: 18px;
}

.add-location {
  gap: 10px;
}

.plus-icon {
  padding: 10px;
  border: 2px solid #662e89;
  border-radius: 5px;
  cursor: pointer;
}

.billing-subscription-info::after,
.billing-history::after {
  content: "";
  display: block;
  height: 2px;
  width: calc(100% + 60px);
  background-color: rgba(225, 225, 225, 0.5);
  margin-left: -30px;
  margin-right: -30px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.billing-subscriptions-heading {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}

.subscriptions-table {
  width: 100%;
}

// .subscriptions-table thead th:last-child {
//   text-align: right;
// }

.subscriptions-table thead {
  background-color: #f7f6fb;
  color: #b0afb4;
}

.subscriptions-table thead th {
  padding: 10px;
  font-size: 14px;
}

.subscriptions-table tbody td {
  font-weight: bold;
  padding: 10px;
  font-size: 12px;
  text-align: center;
}

.subscriptions-table tbody tr td:last-child img {
  display: block;
  margin: auto;
}

.table-primary {
  color: #662e89;
}

.change-card-form-button {
  background: #662e89;
  color: #fff;
  padding: 1rem 2.5rem;
  border-radius: 0.5rem;
  border: none;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  margin-top: 1.5rem;
  font-family: inherit;
  cursor: pointer;
  align-self: end;
  transition: all 0.5s ease-in-out;
}

.change-card-form {
  // display: flex;
  // flex-direction: column;
  // gap: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;

  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.3) !important;
  z-index: 9999;
}

.ReactModal__Content.ReactModal__Content--after-open[aria-label="Change Credit Card"] {
  @media (max-width: 900px) {
    width: 95vw !important;
  }

  @media (max-width: 460px) {
    box-sizing: content-box;
  }
}

.billing-history-table tbody tr td:last-child img {
  display: block;
  margin: auto;
}

.billing-history-table-wrapper,
.subscriptions-table-wrapper {
  // width: 820px;
  overflow-x: auto;

  width: 100%;

  @media (max-width: 1200px) {
    width: 700px;
  }

  @media (max-width: 1100px) {
    width: 600px;
  }

  @media (max-width: 1100px) {
    width: 500px;
  }

  @media (max-width: 900px) {
    width: 700px;
  }

  @media (max-width: 800px) {
    width: 600px;
  }

  @media (max-width: 600px) {
    width: 550px;
  }

  @media (max-width: 460px) {
    width: 410px;
  }
}

.billing-history-table {
  width: 100%;
}

// .billing-history-table th {
//   min-width: 15rem;
//   width: 20%;
// }

// .subscriptions-table th {
//   min-width: 15rem;
//   width: 33.33333%;
// }

.payment-card-title {
  color: #b0afb4;
  font-size: 22px;
  margin-bottom: 10px;
}

.payment-visa-number p {
  font-size: 20px;
  font-weight: bold;
}

.payment-visa-number {
  margin-bottom: 10px;
}

.payment-button {
  color: #fff;
  background-color: #999;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.payment-info-left {
  max-width: 400px;

  @media (max-width: 1100px) {
    max-width: 100%;
    width: 100%;
  }
}

.download-icon {
  width: 15px;
  height: 15px;
  background-color: #662e89;
  padding: 8px;
  border-radius: 5px;
  box-sizing: content-box;
  cursor: pointer;
}

.close-times-icon {
  width: 18px;
  height: 18px;
  padding: 6px;
  background-color: #662e89;
  border-radius: 5px;
  box-sizing: content-box;
  cursor: pointer;
}

.visa-icon {
  height: 40px;
}

.payment-visa-number {
  display: flex;
  align-items: center;
  gap: 10px;
}
